
import React, { Component } from 'react';

import { connect } from 'react-redux';

import {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react';
import {getUserInfo} from '../actions/auth';

import {login} from '../actions/auth';

import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";

import { withTranslation } from 'react-i18next';
import { Trans } from 'react-i18next';
import Spinner from 'react-spinner-material';

import { setup } from 'axios-cache-adapter';

import CONFIG from '../config/config';

import SelectorInstalaciones from './components/installations_select';


const $ = window.$;

/*
const PDFDocument = window.PDFDocument;

var doc = new PDFDocument();
*/


var CONT_axios_Alarms = 0;

var AUX_str_ALL_SLs_Alarms_Disabled =  "";

var CONT_curl_sunrise_sunset =  0;


const axios = setup(
					{
						cache:
								{
									maxAge: 15 * 1000
								}
					}
) // setup()


//--------------------------------------------------------------------------------------------------------------------------



class Main extends Component
{

	constructor ( props )
	{

		super( props );


		this.state = 
					{
						selected_installationgroup: {
														group_name: "",

														id:"",

														info:
																{
																	streetlights: [],

																	available_sensors:[]
																}
													},
						waiting: false,


						str_ALL_SLs_Alarms_Disabled: ""
					};


		this.optionVisibility = -1;


		this.showSpinner = this.showSpinner.bind( this );

	} // constructor ( props )



	//--------------------------------------------------------------------------------------------------------------------------


	componentDidMount ( )
	{

		//window.alert(  "AUI"  );

		this.props.getUserInfo( this.props.auth.token );

		CONT_axios_Alarms = 0;


		CONT_curl_sunrise_sunset =  0;


	} // componentDidMount ( )



	//--------------------------------------------------------------------------------------------------------------------------


	

	showSpinner ( )
	{

		if ( ! this.state.waiting )
		{
			return ( <div/> )

		}// if ( ! this.state.waiting )


		//----


		return (
					<div style={{position: "absolute", left: "0", top: "0", width: "100%", height: "100%", backgroundColor: "rgba(255,255,255,0.7)" , zIndex: "10"}}>

						<div style={{position: "absolute", left: "45%", top: "40%"}}>

							<Spinner radius={120} spinnerColor={"#333"} spinnerWidth={2} visible={true} />

						</div>

					</div>

		);// return


	} // showSpinner ( ) ------



	//--------------------------------------------------------------------------------------------------------------------------



	showProperUnits ( what, unit )
	{

		if ( unit == "kg" )
		{
			if ( what > 1000 )
			{
				what = Math.round( what / 1000 );
				unit = "Tn";
			}//

		}// if

		//-----

		if ( unit == "kWh" )
		{
			if ( what > 1000 )
			{
				what =  Math.round(  parseFloat( what / 1000.0 )  );

				unit= "MWh";
			}//

		}// if

		return  what  +  " "  +  unit;

	} // showProperUnits ( what, unit )



	//--------------------------------------------------------------------------------------------------------------------------


	render ( )
	{

		const { t } =  this.props;


		if ( this.state.waiting )
		{
			return this.showSpinner()

		}// if


		var mapLatlngbounds =  new  this.props.google.maps.LatLngBounds();


		this.state.selected_installationgroup.info.streetlights.map(
																		(  item  ) =>
																		{
																			mapLatlngbounds.extend(  new this.props.google.maps.LatLng( item.latitude,  item.longitude )  );

																			//mapLatlngbounds.extend( new this.props.google.maps.LatLng (item.latitude-0.01,item.longitude-0.01));
																		}
		);// ... .map()

		//----


		if ( this.state.selected_installationgroup.info.streetlights.length == 1 )
		{
			setTimeout(
							( ) =>
							{
								if (  this.map === undefined  )
								{
									return;
								}//

								var zoom =	this.map.map.getZoom();

								this.map.map.setCenter(
														{
															lat:	this.state.selected_installationgroup.latitude,
															lng:	this.state.selected_installationgroup.longitude
														}
								);

								this.map.map.setZoom(  ( zoom > 15 )  ?  15  :  zoom  );
							}

							, 500
			)// setTimeout
		}
		else
		{
			setTimeout(
							( ) =>
							{
								if ( this.map === undefined )
								{
									return;
								}//

								var zoom = this.map.map.getZoom();

								this.map.map.setZoom(  (  zoom > 20  )  ?  20  :  zoom  );
							}

							, 500
			)//setTimeout

		} //else


		//    if(this.state.installationgroups.length<1) {
		//      return <div></div>
		//    } 
		

		$(   $( ".mapa-torres" ).children()[0]   ).css( "max-height",  "450px" );


		//---------


		if (   CONT_axios_Alarms  <=  0   )
		{

			let str_FILTER_VAL =  (  this.props.auth.user.user_profile.userlevel  ==  "EKIONA"  )	?	"true"	:	"false";


			let str_QUERY_INST_GROUP_NAME =		(   this.state.selected_installationgroup.group_name  !=  ""   )
												?
													(   this.state.selected_installationgroup.group_name  !=  "All"   )
													?
														'"streetlight.installation_group":'		+
														'{'										+
															'"filterVal":'	+	'"'				+  this.state.selected_installationgroup.group_name		+	'"'		+	','		+
															'"filterType":"SELECT",'			+
															'"comparator":"=",'					+
															'"caseSensitive":false'				+
														'}, '
													:
														""
												:
													"";

			//--------

			axios.get(
							CONFIG.api_url	+	"/alarms"	+
															"?page=1"	+
															"&filtro="	+

															'{'			+
																'"type":"filter",'	+
																
																'"value":'				+
																'{'						+
																	'"sortField":null,'	+
																	
																	'"filters":'		+
																	'{'					+


																		str_QUERY_INST_GROUP_NAME	+


																		'"disabled_alarms":'					+
																		'{'										+
																			'"filterVal":'						+	str_FILTER_VAL	+	','		+
																			'"filterType":"SELECT",'			+
																			'"comparator":"=",'					+
																			'"caseSensitive":false'				+
																		'}'										+

																	'},'										+
																	
																	'"page":0,'									+
																	'"sizePerPage":100'							+

																'}'												+

															'}'
							,																//'&fromDate=1587748699&toDate=1653377439',
																							//  "filterVal":false,  -->  AHORA: "filterVal":true,
							{
								"headers":
											{
												'Content-Type': 'application/json',
												'Authorization': "Token " + this.props.auth.token
											}
							}
			).then(
						( result ) =>
						{


							AUX_str_ALL_SLs_Alarms_Disabled =  JSON.stringify(  result.data.info.all_streetlights  );


							//window.alert(  AUX_str_ALL_SLs_Alarms_Disabled  );


							AUX_str_ALL_SLs_Alarms_Disabled =  AUX_str_ALL_SLs_Alarms_Disabled.toLowerCase();



							//window.alert(  AUX_str_ALL_SLs_Alarms_Disabled  );


							//console.log(  "HA LLEGADOF AQUÍ, ALARMAS"  );
							//console.log(  AUX_str_ALL_SLs_Alarms_Disabled  );
							//console.log(  result.data  );



							this.state.str_ALL_SLs_Alarms_Disabled =  AUX_str_ALL_SLs_Alarms_Disabled;

							
												
							this.setState(	
											{
												str_ALL_SLs_Alarms_Disabled: 	AUX_str_ALL_SLs_Alarms_Disabled,
											}
							);//


							//CONT_axios_Alarms++;
							
						}

			);// ... .then()



			//-----

			CONT_axios_Alarms++;



			//-------------------------



			/*

			axios.get(
							"https://gml.noaa.gov/grad/solcalc/table.php?lat=43.327555&lon=-1.971517&year=2022",																//'&fromDate=1587748699&toDate=1653377439',
																							//  "filterVal":false,  -->  AHORA: "filterVal":true,
							{
								"headers":
											{
												'Content-Type': 'application/json'	//,
												//'Authorization': "Token " + this.props.auth.token
											}
							}
			).then(
						( result ) =>
						{




							console.log(  result.data );

							//CONT_axios_Alarms++;
							
						}

			);// ... .then()

			*/


			//---------------------------------------------------

			/*
			if (   CONT_curl_sunrise_sunset  ==  0   )
			{

				var myurl = "https://developer.mozilla.org/en-US/docs/Web/API/Web_Workers_API";		//"https://gml.noaa.gov/grad/solcalc/table.php?lat=43.327555&lon=-1.971517&year=2022";


				var xhr =  new XMLHttpRequest();
	
				xhr.open( "GET", myurl );
					
				// If specified, responseType must be empty string or "document"
				xhr.responseType = 'document';

				
				//xhr.onreadystatechange =	function ( )
				//							{
				//								//if (   xhr.readyState  ===  4   )
				//								//{
				//									console.log(  xhr.status  );
				//									console.log(  JSON.stringify( xhr.responseText )  );
				//								//}
				//							};
				


				

				// Force the response to be parsed as XML
				xhr.overrideMimeType( 'text/xml' );


				xhr.onload =	function ()
								{

									//if (   xhr.readyState === xhr.DONE   &&   xhr.status === 200   )
									//{
										console.log(  xhr.response,  xhr.responseXML  );
									//}
								};
				  


				//xhr.withCredentials =  true;
				xhr.send(  );	// null
	
			}// if (   CONT_curl_sunrise_sunset  ==  0   )
			*/
			//-------------------------


		}// if (   CONT_axios_Alarms == 0   )



		//console.log(  this.props.auth.user );
		//console.log( this.state.selected_installationgroup.clouds_year/*_Historic/* );


		/*
		const our_JSON_sunrise =  this.state.selected_installationgroup.sunrise_sunset_year;
		const our_JSON_clouds =  this.state.selected_installationgroup.clouds_year//_Historic//;


		if (   our_JSON_sunrise  !==  null   )
		{
			if (   our_JSON_sunrise  !=  undefined   )
			{
				//if (   our_JSON  !=  ""   )
				//{

				const our_JSON_OBJ_sunrise = JSON.parse(  our_JSON_sunrise  );
			
				console.log( our_JSON_OBJ_sunrise[ 5 ][ 16 ][ "sunrise" ] );

				//}
			}
		}//



		if (   our_JSON_clouds  !==  null   )
		{
			if (   our_JSON_clouds  !=  undefined   )
			{
				//if (   our_JSON  !=  ""   )
				//{

				const our_JSON_OBJ_clouds = JSON.parse(  our_JSON_clouds  );
			
				console.log( our_JSON_OBJ_clouds[ "2022" ] );

				console.log( our_JSON_OBJ_clouds[ "2022" ][ 5 ][ 16 ][ "Clouds" ] );

				//}
			}
		}//
		*/

		//-----------------------------------------------------------------------------------------

		return( 
					<div className="container-fluid">
						<div className="row">

							<div id="dashboard" className="col-sm-12 col-md-6">
								<div className="col-dashboard">

									<div className="info-user">       

										<h2>
											<strong> { this.props.t( "main.hello" ) } { this.props.auth.user.first_name }, </strong>
											
											{ this.props.t( "main.this_is_your_dashboard" ) }
										</h2>

										<p dangerouslySetInnerHTML={{__html: this.props.t("main.this_system_lets_you_visualize")}}></p>

									</div>


									<div className="row">
										<div className="col">

											<img src="img/ico/gray/ico-c-marcas.svg" alt=""/>

											<h3>
											{
												this.props.auth.user.summary_info && 
												<span> { this.props.auth.user.summary_info.installationgroups } </span>
											}

											{
												( this.props.auth.user.summary_info.installationgroups > 1 )  ?  this.props.t( "main.instalaciones" )  :  this.props.t( "main.instalacion" )
											}
											</h3>

											<p> { this.props.t( "main.of_ekiona_streetlights" ) } </p>

										</div>

										<div className="col">

											<img src="img/ico/gray/ico-c-sun.svg" alt=""/>

											<h3>
											{
												this.props.auth.user.summary_info  &&

												<span> { this.props.auth.user.summary_info.streetlights } </span>
											}
											
											{
												(  this.props.auth.user.summary_info.streetlights > 1  )  ?  this.props.t( "main.solar_streetlights" )  :  this.props.t( "main.solar_streetlight" )
											}
											</h3>

											<p> { this.props.t( "main.connected_to_ilumek") } </p>

										</div>
									</div>


									<div className="row">


										<div className="col">

											<img src="img/ico/gray/ico-c-led.svg" alt=""/>
										
											{
											( this.props.auth.user.summary_info  &&  this.props.auth.user.summary_info.total_led_power > 1000 )  && 

											<h3>
												{  ( this.props.auth.user.summary_info.total_led_power / 1000  ).toFixed(0)	} {  this.props.t("main.installed_kw")  }
											</h3>
											}

											{
											(  this.props.auth.user.summary_info  &&  this.props.auth.user.summary_info.total_led_power <= 1000  )  &&
											
											<h3>
												{ this.props.auth.user.summary_info.total_led_power } { this.props.t("main.installed_w") }
											</h3>
											}

											<p>
												{ this.props.t("main.total_luminic_power") } { this.state.selected_installationgroup.info.mean_efficiency && ( this.state.selected_installationgroup.info.mean_efficiency.toFixed(0) ) } lm/W
											</p>

										</div>

										<div className="col">
											<img src="img/ico/gray/ico-c-energy.svg" alt=""/>

											<h3>

												{ 
												/*
												this.props.auth.user.summary_info &&
												
												(  this.props.auth.user.summary_info.total_used_energy  /  1000.0  ).toFixed( 1 )	+  " kWh " 	+  this.props.t("main.consumed")				//.toFixed(0)
												*/
												} 


												{
												
												(
														(  (  this.props.auth.user.summary_info.total_used_energy  /  1000.0  )  <  10000.0  )
														?
															(  this.props.auth.user.summary_info.total_used_energy  /  1000.0  ).toFixed( 1 )
														:
															(  this.props.auth.user.summary_info.total_used_energy  /  1000000.0  ).toFixed( 1 )
												)

												+

												(
													( (  this.props.auth.user.summary_info.total_used_energy  /  1000.0  )  <  10000.0  )
													?
														" kWh "
													:
														" MWh "
												)

												+

												this.props.t("main.consumed")

												}


											</h3>

											<p> { this.props.t("main.kenlok_efficient_energy") } </p>
										</div>


									</div>


									<div className="row">
										
										<div className="col" style={{marginBottom: "10px"}}>

											<img src="img/ico/gray/ico-enchufe2.svg" alt=""/>

											<h3>
												{
												this.props.auth.user.summary_info &&

												<span>
													{
													//this.showProperUnits(   parseFloat(  ( this.props.auth.user.summary_info.total_saved_energy ) / 1000.0  ).toFixed(0),   "kWh"   )

													// SE HACE LA DIVISIÓN: "this.props.auth.user.summary_info.total_led_power   /   this.props.auth.user.summary_info.streetlights  )   /   1000.0   )" PORQUE EL "TOTAL_LED_POWER" ES EL SUMATORIO DE LOS "LED POWER" DE TODAS LAS FAROLAS
													
													//Math.round(   (  5.0  *  this.props.auth.user.summary_info.total_night_time  *  this.props.auth.user.summary_info.total_led_power   /   this.props.auth.user.summary_info.streetlights  )   /   1000.0   )
													
													(
														(  (   (  5.0  *  this.props.auth.user.summary_info.total_night_time  *  this.props.auth.user.summary_info.total_led_power   /   this.props.auth.user.summary_info.streetlights  )   /   1000.0   )  <  10000.0  )
														?
															(   (  5.0  *  this.props.auth.user.summary_info.total_night_time  *  this.props.auth.user.summary_info.total_led_power   /   this.props.auth.user.summary_info.streetlights  )   /   1000.0   ).toFixed( 1 )
														:
															(   (  5.0  *  this.props.auth.user.summary_info.total_night_time  *  this.props.auth.user.summary_info.total_led_power   /   this.props.auth.user.summary_info.streetlights  )   /   1000000.0   ).toFixed( 1 )
													)

													+

													(
														( (   (  5.0  *  this.props.auth.user.summary_info.total_night_time  *  this.props.auth.user.summary_info.total_led_power   /   this.props.auth.user.summary_info.streetlights  )   /   1000.0   )  <  10000.0  )
														?
															" kWh"
														:
															" MWh"
													)
													
													//( this.props.auth.user.summary_info.total_night_time ).toFixed(0)  +  " horas"  +  " * "  +
													//( this.props.auth.user.summary_info.total_led_power ).toFixed(0)  +  " (W) led_power"  +  " = " +

													//( this.props.auth.user.summary_info.total_hypotetical_energy  /  1000.0).toFixed(0)  +  " KWh"
													

													}

													{
													//<br/>
													}

													{
													
													//this.showProperUnits(   parseFloat(  ( this.props.auth.user.summary_info.total_saved_energy ) / 1000.0  ).toFixed(0),   "kWh"   )

													//( this.props.auth.user.summary_info.total_saved_energy  /  1000.0).toFixed(0)  +  " KWh"
													}
													
												</span>
												
												} {this.props.t( "main.saved" )}
											</h3>


											<p>
												{
												this.props.t( "main.conventional_bulb_equivalent" )
												}
											</p>

										</div>


										<div className="col" style={{marginBottom: "10px"}}>
										
											<img src="img/ico/gray/ico-fabricas.svg" alt=""/>
											
											<h3>
												{ this.props.auth.user.summary_info  && 

												// 09/05/22 CAMBIAMOS EL COEFICIENTE, SEǴUN LA RECTIFICACIÓN HABLADO CON ARNAITZ, DEL ANTIGUO "0.35" A "0.25"

												// 17/05/22 CAMBIAMOS EL COEFICIENTE, SEǴUN LA NUEVA TABLA/EXCEL PROPORCIONADA POR ARNAITZ, DE "0.25" A "0.14"

												<span>{ this.showProperUnits( ( this.props.auth.user.summary_info.total_used_energy / 1000 * 0.14 ).toFixed(0), "kg" ) }</span>} CO<sub>2</sub>
											</h3>

											<p> { this.props.t( "main.less_co2_in_atmosphere" ) } </p>
										</div>
										
									</div>


								</div>
							</div>


							{ /* ---------------------------------------------------------------------------------------------- */ }

							{
							this.state.selected_installationgroup.group_name !== "---"  &&

							<div className="col-sm-12 col-md-6">

								<div id="info-torres-resume">

									<div className="mapa-torres" style={{width: "100%", height: "450px"}}>
											
										<Map
											google = { this.props.google }
											bounds = { mapLatlngbounds }
											style = { {width: "100%", height: "450px"} }
											mapTypeControl = { true }

											initialCenter = 
															{
																{
																	lat: `${this.state.selected_installationgroup.latitude}`,
																	lng: `${this.state.selected_installationgroup.longitude}`
																}
															}

											streetViewControl = { false }
											fullscreenControl = { false }
											zoomControl = { false }
											gestureHandling = { true }

											ref =
													{
														( e ) =>
														{
															if ( e )
															{
																e.map.setMapTypeId( 'satellite' );
																e.map.setTilt(0);
															}
														}
													}
										>
										{
											this.state.selected_installationgroup.info.streetlights.map(
																											( item ) =>
																											{
																												//let url = CONFIG[ "server_url" ]  +  "/"  +  item.marker;

																												/*
																												let icon =
																															{
																																url: url, 

																																size: { width: 128, height: 128 },

																																anchor: { x: 63, y: 113 },
																																scaledSize: { width: 64, height: 64 }
																															};
																												*/


																												let icon =
																															{
																																url:	
																																		(  this.props.auth.user.user_profile.userlevel  ==  "EKIONA"  )
																																		?
																																			`${ CONFIG.server_url }/${ item.marker }`
																																		:
																																			(
																																				(    (  this.state.str_ALL_SLs_Alarms_Disabled  ).indexOf(   ( item.serial_streetlight ).toLowerCase()   )  <=  -1    )
																																		
																																				?
																																					(
																																						"https://api.ilumek.com/static/markers/green"	
																																																		+	(
																																																				(  item.cpu_is_master  ==  true  )
																																																				?
																																																					"dot"
																																																				:
																																																					""
																																																			)

																																																		+	"hole32.png"
																																					)
																																				:
																																					`${ CONFIG.server_url }/${ item.marker }`
																																			)
																																			,


																																size: { width: 128, height: 128 },

																																anchor: { x: 63, y: 113 },

																																scaledSize: { width: 64, height: 64 }

																																//scaledSize: new this.props.google.maps.Size( 45, 45 )
																															};

																												//----

																												return  <Marker

																															title = { item.serial_streetlight }
																															name = { item.serial_streetlight}


																															icon = { /*url*/ icon.url }


																															position = { {lat: `${item.latitude}`, lng: `${item.longitude}`} } 
																															style = { {zIndex: 19999} }

																															onClick =
																																		{
																																			( props, marker, e ) =>
																																			{
																																				//console.log( item );

																																				CONFIG[ "setPreferredInstallationGroup" ]( item.installation_id );

																																				this.props.history.push( `/instalaciones?installation_id=${item.installation_id}&sl_id=${item.id}` );  //`

																																				if( !e.wa )
																																				{
																																					return;
																																				}

																																				e.wa.preventDefault();

																																				return false;
																																			}
																																		}
																														/>
																											}
											) // ... .map()
										}

											<InfoWindow onClose={this.onInfoWindowClose}>
												<div>
													<h1> ASDASDASD </h1>
												</div>
											</InfoWindow>


										</Map>

									</div>


									<div style={{minHeight: "250px"}}>

										<ul id="torres">

											<div style={{paddingRight: "30px", marginLeft: "-10px", marginTop: "20px"}} id="selectDiv">

												<center> <Trans>main.alarm_level</Trans>: </center>

												<center style={{marginTop: "10px"}}>
													<img src="img/markers/greenhole.png" style={{width: "20px"}}/><Trans>main.correct</Trans>
													&nbsp;&nbsp;
													<img src="img/markers/orangehole.png" style={{width: "20px"}}/><Trans>main.low</Trans>
													&nbsp;&nbsp;
													<img src="img/markers/redhole.png" style={{width: "20px"}}/><Trans>main.high</Trans>                                
												</center>

												<br/>


												<SelectorInstalaciones

													showAll = { true }

													onDataLoaded = 
																	{
																		( e ) =>
																		{
																			//this.setState({selected_installationgroup: e});

																			CONT_axios_Alarms = 0;
																		}
																	}

													onDataChanged = 
																	{
																		( e ) =>
																		{
																			CONT_axios_Alarms = 0;

																			this.setState( { selected_installationgroup: e } );

																			//CONT_axios_Alarms = 0;
																		}
																	}

													parent = { this.props.parent } 
													selectorClass = "col-md-12"
												/>


											</div>

										</ul>


										<ul id="listado-detalles">
											
											<li>
												<div className="dato">
													<p>{this.props.t("main.streetlight_number")}</p>
												</div>

												<div className="resultado">
													<p>{this.state.selected_installationgroup.info.streetlights.length}</p>
												</div>
											</li>
											
											<li>
												<div className="dato">
													<p>{this.props.t("main.total_power")}</p>
												</div>

												<div className="resultado">
													<p>{this.state.selected_installationgroup.info.total_power}W</p>
												</div>
											</li> 

											{
											this.state.selected_installationgroup.id != "all"  &&

											<li>
												<div className="dato">
													<p>{this.props.t("main.installation_date")}</p>
												</div>

												<div className="resultado">
													<p>{CONFIG.utils.toLocalDate(this.state.selected_installationgroup.installation_date)}</p>
												</div>
											</li>
											}


											<li>

												<div className="dato">
													<p> { this.props.t( "main.sensors" ) } </p>
												</div>

												<div className="resultado">
												
													<ul>
													{
														[
															"has_air_sensor",
															"has_noise_sensor",
															"has_polen_sensor",
															"has_presence",
															"has_sos_button",
															"has_surveillance",
															"has_traffic_sensor",
															"has_uv_sensor",
															"hybrid"

														].map(
																( item, index ) =>
																{
																	if (  this.state.selected_installationgroup  &&  this.state.selected_installationgroup.info.available_sensors[item]  )
																	{
																		//console.log(CONFIG.getIconImg(item));
																		return (  <li> { CONFIG.getIconImg( item, this.props.t ) } </li>  )
																	}
																}
														)
													}

													{ 
														( this.state.selected_installationgroup  &&  JSON.stringify( this.state.selected_installationgroup.info.available_sensors ).indexOf( "true" ) < 0 )  &&
														<div> <Trans>main.no_sensors</Trans> </div>
													}
													</ul>
												
												</div>

											</li>

										</ul>


									</div>


								</div>
							</div>
							}

						</div>
					</div>

		) // return ()

	} // render ( ) 
	
} // clase Main ()

//---------------

const mapStateToProps = 
						function ( state )
						{
							return	{
										auth: state.auth,
										core: state.core
									}
						}


const mapDispatchToProps = 
							{
								login: login,
								getUserInfo: getUserInfo 
							}


export default GoogleApiWrapper(  { apiKey: CONFIG.google_maps_api_key }  )(  withRouter(  connect( mapStateToProps, mapDispatchToProps )( withTranslation()(Main) )  )  );
